import styled from 'styled-components';

export const Container = styled.div`
  width: 90%;
  max-width: 500px;
  height: 90vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;

export const Boddy = styled.div`
  background-color: silver;
  width: 100%;
  max-width: 700px;
  min-height: 40vh;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex: 3;
  flex-direction: column;
  align-content: flex-start;
  overflow: scroll;
  padding: 8px 0px 8px 8px;
  margin-bottom: 4px;
`;

export const Logo = styled.img`
  width: 90px;
  margin-bottom: 8px;
  margin-top: 8px;
`;

export const SubTitle = styled.h2`
  font-size: 24px;
  margin-bottom: 8px;
  font-family: 'Roboto Regular';
`;

export const Footer = styled.div`
  width: 100%;
  margin-bottom: 16px;

  Button {
    margin-bottom: 4px;
  }
`;
