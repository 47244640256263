import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { format, getDay } from 'date-fns';
import firebase from '../../services/firebaseConfig';
import { GroupModel } from '../../models/GroupModel';
import { createProvider } from '../../store/ducks/provider/actions';
import { ProviderModel } from '../../models/ProviderModel';
import {
  createProduct,
  cleanProduct,
} from '../../store/ducks/products/actions';
import { ProductModel } from '../../models/ProductModel';
import { createGroup, cleanGroup } from '../../store/ducks/groups/actions';
import { createEdge, cleanEdge } from '../../store/ducks/edge/actions';
import { cleanPayment, createPayment } from '../../store/ducks/payment/actions';
import { PaymentModel } from '../../models/PaymentModel';
import { createNeigh, cleanNeigh } from '../../store/ducks/neigh/actions';
import { NeighModel } from '../../models/NeighModel';
// import { createCoords } from '../../store/ducks/coords/actions';

import { Container, Logo, Title, SubTitle } from './styles';
import { CustomerModel } from '../../models/CustomerModel';
import { createCustomer } from '../../store/ducks/customer/actions';
import logo from '../../assets/order-food.png';
import { EdgeModel } from '../../models/EdgeModel';

interface ParamTypes {
  idProvider: string;
}

const Provider: React.FC = () => {
  const { idProvider } = useParams<ParamTypes>();

  const history = useHistory();
  const dispatch = useDispatch();
  const offeredToday = useCallback((day: number, data: ProductModel) => {
    const hour = format(new Date(), 'HH:mm:ss');
    const toDayDate = format(new Date(), 'MM/dd/yyyy');
    const toDay = `${toDayDate} ${hour}`;

    if (day === 0) {
      if (data.sunday === 'S') {
        const startOffer = `${toDayDate} ${data.sunday_start}`;
        const stopOffer = `${toDayDate} ${data.sunday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    if (day === 1) {
      if (data.monday === 'S') {
        const startOffer = `${toDayDate} ${data.monday_start}`;
        const stopOffer = `${toDayDate} ${data.monday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    if (day === 2) {
      if (data.tuesday === 'S') {
        const startOffer = `${toDayDate} ${data.tuesday_start}`;
        const stopOffer = `${toDayDate} ${data.tuesday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    if (day === 3) {
      if (data.wednesday === 'S') {
        const startOffer = `${toDayDate} ${data.wednesday_start}`;
        const stopOffer = `${toDayDate} ${data.wednesday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    if (day === 4) {
      if (data.thursday === 'S') {
        const startOffer = `${toDayDate} ${data.thursday_start}`;
        const stopOffer = `${toDayDate} ${data.thursday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    if (day === 5) {
      if (data.friday === 'S') {
        const startOffer = `${toDayDate} ${data.friday_start}`;
        const stopOffer = `${toDayDate} ${data.friday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    if (day === 6) {
      if (data.saturday === 'S') {
        const startOffer = `${toDayDate} ${data.saturday_start}`;
        const stopOffer = `${toDayDate} ${data.saturday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    return false;
  }, []);

  useEffect(() => {
    // navigator.geolocation.getCurrentPosition(position => {
    //   const { latitude, longitude } = position.coords;
    //   dispatch(createCoords({ lat: latitude, lng: longitude, zoom: 15 }));
    // });

    // verifica se existe o fone no local storage
    const mwCustomer: CustomerModel = JSON.parse(
      String(localStorage.getItem('MW-CUSTOMER')),
    ) as CustomerModel;

    if (mwCustomer) {
      dispatch(createCustomer(mwCustomer));
    }

    const loadProvide = () => {
      localStorage.clear();
      firebase
        .auth()
        .signInWithEmailAndPassword('freitagnx@gmail.com', '3oiqecdc')
        .then(() => {
          firebase
            .firestore()
            .collection('providers')
            .orderBy('name')
            .where('id', '==', idProvider)
            .get()
            .then(result => {
              result.forEach(item => {
                dispatch(
                  createProvider({
                    id: idProvider,
                    logo: item.data().logo,
                    email: item.data().email,
                    password: item.data().password,
                    cnpj: item.data().cnpj,
                    fantasy: item.data().fantasy,
                    feeDelivery: item.data().feeDelivery,
                    feeDeliveryMode: item.data().feeDeliveryMode,
                    ray: item.data().ray,
                    open: item.data().open,
                    singleEdge: item.data().singleEdge,
                  } as ProviderModel),
                );
                localStorage.setItem(
                  'provider',
                  JSON.stringify({
                    id: idProvider,
                    logo: item.data().logo,
                    email: item.data().email,
                    password: item.data().password,
                    cnpj: item.data().cnpj,
                    fantasy: item.data().fantasy,
                    feeDelivery: item.data().feeDelivery,
                    feeDeliveryMode: item.data().feeDeliveryMode,
                    ray: item.data().ray,
                    open: item.data().open,
                    singleEdge: item.data().singleEdge,
                  }),
                );
              });
              firebase
                .firestore()
                .collection('products')
                .orderBy('description')
                .where('idProvider', '==', idProvider)
                .where('active', '==', 'S')
                .get()
                .then(result => {
                  const allGroups: GroupModel[] = [];
                  dispatch(cleanProduct({} as ProductModel));
                  dispatch(cleanEdge({} as EdgeModel));
                  const day = getDay(new Date());

                  result.forEach(item => {
                    const newItem: ProductModel = {
                      id: item.data().id,
                      idProvider,
                      code: item.data().code,
                      description: item.data().description,
                      unity: item.data().unity,
                      price: item.data().price,
                      group: item.data().group,
                      subgroup: item.data().subgroup,
                      active: item.data().active,
                      fractioned: item.data().fractioned,
                      fractions: item.data().fractions,
                      edge: item.data().edge,
                      additional: item.data().additional,
                      portionSize: item.data().portionSize,
                      web_img_64: item.data().web_img_64,
                      web_borda_subgrupo: item.data().web_borda_subgrupo,
                      monday: item.data().monday,
                      monday_start: item.data().monday_start,
                      monday_stop: item.data().monday_stop,
                      tuesday: item.data().tuesday,
                      tuesday_start: item.data().tuesday_start,
                      tuesday_stop: item.data().tuesday_stop,
                      wednesday: item.data().wednesday,
                      wednesday_start: item.data().wednesday_start,
                      wednesday_stop: item.data().wednesday_stop,
                      thursday: item.data().thursday,
                      thursday_start: item.data().thursday_start,
                      thursday_stop: item.data().thursday_stop,
                      friday: item.data().friday,
                      friday_start: item.data().friday_start,
                      friday_stop: item.data().friday_stop,
                      saturday: item.data().saturday,
                      saturday_start: item.data().saturday_start,
                      saturday_stop: item.data().saturday_stop,
                      sunday: item.data().sunday,
                      sunday_start: item.data().sunday_start,
                      sunday_stop: item.data().sunday_stop,
                    };
                    if (item.data().edge === 'S') {
                      dispatch(
                        createEdge({
                          codeEdge: item.data().id,
                          edge: item.data().description,
                          priceEdge: item.data().price,
                          subgroup: item.data().web_borda_subgrupo,
                        } as EdgeModel),
                      );
                    } else {
                      dispatch(createProduct(newItem as ProductModel));
                    }
                    let insert = true;
                    allGroups.forEach(g => {
                      if (g.group === item.data().subgroup) {
                        insert = false;
                      }
                    });
                    const offered = offeredToday(day, newItem);
                    if (
                      insert === true &&
                      item.data().edge !== 'S' &&
                      offered
                    ) {
                      allGroups.push({
                        id: item.data().id,
                        group: item.data().subgroup,
                        fractioned: item.data().fractioned,
                        fractions: item.data().fractions,
                        web_borda_subgrupo: item.data().web_borda_subgrupo,
                      });
                    }
                  });
                  dispatch(cleanGroup({} as GroupModel));
                  allGroups.forEach(item => {
                    dispatch(
                      createGroup({
                        id: item.id,
                        group: item.group,
                        fractioned: item.fractioned,
                        fractions: item.fractions,
                        web_borda_subgrupo: item.web_borda_subgrupo,
                      } as GroupModel),
                    );
                  });
                  firebase
                    .firestore()
                    .collection('formPayment')
                    .orderBy('formPayment')
                    .where('idProvider', '==', idProvider)
                    .get()
                    .then(result => {
                      // console.log('lidos dados das formas de pgto');
                      dispatch(cleanPayment({} as PaymentModel));
                      result.forEach(item => {
                        dispatch(
                          createPayment({
                            id: item.data().id,
                            formPayment: item.data().formPayment,
                            change: item.data().change,
                          } as PaymentModel),
                        );
                      });

                      dispatch(cleanNeigh({} as NeighModel));
                      firebase
                        .firestore()
                        .collection('neighborhood')
                        .orderBy('name')
                        .where('idProvider', '==', idProvider)
                        .where('active', '==', 'S')
                        .get()
                        .then(result => {
                          // console.log('lidos dados dos bairros');
                          result.forEach(item => {
                            dispatch(
                              createNeigh({
                                id: item.data().id,
                                code: item.data().code,
                                idProvider: item.data().idProvider,
                                name: item.data().name,
                                feeDelivery: item
                                  .data()
                                  .feeDelivery.replace(',', '.'),
                                city: item.data().city,
                                uf: item.data().uf,
                                ibge: item.data().ibge,
                              } as NeighModel),
                            );
                          });
                          history.push('/');
                        })
                        .catch(erro => {
                          console.log(erro);
                        });
                    });
                });
            });
        })
        .catch(erro => {
          alert(erro);
        });
    };
    loadProvide();
  }, [idProvider, dispatch, history]);

  return (
    <Container>
      <Logo className="imgLogin" src={logo} alt="Logo" />
      <Title>Mettre Smart</Title>
      <SubTitle>Carregando informações...</SubTitle>
    </Container>
  );
};

export default Provider;
