import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { parse, format, getDay, getTime } from 'date-fns';
import firebase from '../../services/firebaseConfig';
import { GroupModel } from '../../models/GroupModel';
import { createProvider } from '../../store/ducks/provider/actions';
import { ProviderModel } from '../../models/ProviderModel';
import {
  cleanProduct,
  createProduct,
} from '../../store/ducks/products/actions';
import {
  cleanProductFilter,
  createProductFilter,
} from '../../store/ducks/productsFilter/actions';
import { ProductModel } from '../../models/ProductModel';
import { createGroup, cleanGroup } from '../../store/ducks/groups/actions';
import { cleanPayment, createPayment } from '../../store/ducks/payment/actions';
import { PaymentModel } from '../../models/PaymentModel';
import { createNeigh, cleanNeigh } from '../../store/ducks/neigh/actions';
import { NeighModel } from '../../models/NeighModel';
import { RootState } from '../../store/ducks/combineReducers';
// import { CustomerModel } from '../../models/CustomerModel';
// import { createCustomer } from '../../store/ducks/customer/actions';
import { createRequest } from '../../store/ducks/request/actions';
import { FlavorModel } from '../../models/FlavorModel';
import { createFlavor } from '../../store/ducks/flavors/actions';
import { ItemModel } from '../../models/ItemModel';
import { createItem } from '../../store/ducks/items/actions';
import { createItemSelected } from '../../store/ducks/itemSelected/actions';
import { createGroupSelected } from '../../store/ducks/groupSelected/actions';
import { createFlavorSelected } from '../../store/ducks/flavorSelected/actions';
// import { createCoords } from '../../store/ducks/coords/actions';
import { useToast } from '../../hooks/toast';

import { Container, Logo, Title, SubTitle } from './styles';

import logo from '../../assets/order-food.png';
import { cleanEdge, createEdge } from '../../store/ducks/edge/actions';
import { EdgeModel } from '../../models/EdgeModel';

const LoadStorage: React.FC = () => {
  const history = useHistory();
  const callStorage = useSelector(
    (state: RootState) => state.callLoadStorage.data,
  );
  // const products = useSelector(state => state.products);
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const [providerLoaded, setProviderLoaded] = useState<boolean>(false);
  const [requestLoaded, setRequestLoaded] = useState<boolean>(false);
  const [itemsLoaded, setItemsLoaded] = useState<boolean>(false);
  const [itemSelLoaded, setItemSelLoaded] = useState<boolean>(false);
  const [groupSelLoaded, setGroupSelLoaded] = useState<boolean>(false);
  const [flavorSelLoaded, setFlavorSelLoaded] = useState<boolean>(false);
  const [flavorsLoaded, setFlavorsLoaded] = useState<boolean>(false);

  const offeredToday = useCallback((day: number, data: ProductModel) => {
    const hour = format(new Date(), 'HH:mm:ss');
    const toDayDate = format(new Date(), 'MM/dd/yyyy');
    const toDay = `${toDayDate} ${hour}`;

    if (day === 0) {
      if (data.sunday === 'S') {
        const startOffer = `${toDayDate} ${data.sunday_start}`;
        const stopOffer = `${toDayDate} ${data.sunday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    if (day === 1) {
      if (data.monday === 'S') {
        const startOffer = `${toDayDate} ${data.monday_start}`;
        const stopOffer = `${toDayDate} ${data.monday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    if (day === 2) {
      if (data.tuesday === 'S') {
        const startOffer = `${toDayDate} ${data.tuesday_start}`;
        const stopOffer = `${toDayDate} ${data.tuesday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    if (day === 3) {
      if (data.wednesday === 'S') {
        const startOffer = `${toDayDate} ${data.wednesday_start}`;
        const stopOffer = `${toDayDate} ${data.wednesday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    if (day === 4) {
      if (data.thursday === 'S') {
        const startOffer = `${toDayDate} ${data.thursday_start}`;
        const stopOffer = `${toDayDate} ${data.thursday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    if (day === 5) {
      if (data.friday === 'S') {
        const startOffer = `${toDayDate} ${data.friday_start}`;
        const stopOffer = `${toDayDate} ${data.friday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    if (day === 6) {
      if (data.saturday === 'S') {
        const startOffer = `${toDayDate} ${data.saturday_start}`;
        const stopOffer = `${toDayDate} ${data.saturday_stop}`;
        if (
          Date.parse(toDay) >= Date.parse(startOffer) &&
          Date.parse(toDay) <= Date.parse(stopOffer)
        ) {
          return true;
        }
      }
      return false;
    }
    return false;
  }, []);

  const loadProducts = useCallback(
    (id: string) => {
      // console.log('gselected');
      const day = getDay(new Date());
      const storeGroupSelected = JSON.parse(
        String(localStorage.getItem('gselected')),
      );
      firebase
        .firestore()
        .collection('products')
        .orderBy('description')
        .where('idProvider', '==', id)
        .where('active', '==', 'S')
        .get()
        .then(result => {
          dispatch(cleanEdge({} as EdgeModel));
          dispatch(cleanProductFilter({} as ProductModel));
          dispatch(cleanProduct({} as ProductModel));
          const allGroups: GroupModel[] = [];
          // console.log(result.query());
          result.forEach(item => {
            if (item.data().edge === 'S') {
              dispatch(
                createEdge({
                  codeEdge: item.data().id,
                  edge: item.data().description,
                  priceEdge: item.data().price,
                  subgroup: item.data().web_borda_subgrupo,
                } as EdgeModel),
              );
            } else {
              const newItem: ProductModel = {
                id: item.data().id,
                idProvider: id,
                code: item.data().code,
                description: item.data().description,
                unity: item.data().unity,
                price: item.data().price,
                group: item.data().group,
                subgroup: item.data().subgroup,
                active: item.data().active,
                fractioned: item.data().fractioned,
                fractions: item.data().fractions,
                edge: item.data().edge,
                additional: item.data().additional,
                portionSize: item.data().portionSize,
                web_img_64: item.data().web_img_64,
                web_borda_subgrupo: item.data().web_borda_subgrupo,
                monday: item.data().monday,
                monday_start: item.data().monday_start,
                monday_stop: item.data().monday_stop,
                tuesday: item.data().tuesday,
                tuesday_start: item.data().tuesday_start,
                tuesday_stop: item.data().tuesday_stop,
                wednesday: item.data().wednesday,
                wednesday_start: item.data().wednesday_start,
                wednesday_stop: item.data().wednesday_stop,
                thursday: item.data().thursday,
                thursday_start: item.data().thursday_start,
                thursday_stop: item.data().thursday_stop,
                friday: item.data().friday,
                friday_start: item.data().friday_start,
                friday_stop: item.data().friday_stop,
                saturday: item.data().saturday,
                saturday_start: item.data().saturday_start,
                saturday_stop: item.data().saturday_stop,
                sunday: item.data().sunday,
                sunday_start: item.data().sunday_start,
                sunday_stop: item.data().sunday_stop,
              };
              dispatch(createProduct(newItem));
              if (storeGroupSelected) {
                if (newItem.subgroup === storeGroupSelected.group) {
                  // console.log('passou por aqui');
                  dispatch(createProductFilter(newItem));
                }
              }

              let insert = true;
              allGroups.forEach(g => {
                if (g.group === item.data().subgroup) {
                  insert = false;
                }
              });
              const offered = offeredToday(day, newItem);
              if (insert === true && item.data().edge !== 'S' && offered) {
                allGroups.push({
                  id: item.data().id,
                  group: item.data().subgroup,
                  fractioned: item.data().fractioned,
                  fractions: item.data().fractions,
                  web_borda_subgrupo: item.data().web_borda_subgrupo,
                });
              }
            }
          });
          // até aqui

          dispatch(cleanGroup({} as GroupModel));
          const orderGroup: string[] = [];
          allGroups.forEach(item => {
            orderGroup.push(item.group);
          });

          orderGroup.sort();

          orderGroup.forEach(adGroup => {
            allGroups.forEach(item => {
              if (item.group === adGroup) {
                dispatch(
                  createGroup({
                    id: item.id,
                    group: item.group,
                    fractioned: item.fractioned,
                    fractions: item.fractions,
                    web_borda_subgrupo: item.web_borda_subgrupo,
                  }),
                );
              }
            });
          });
        });
    },
    [dispatch],
  );

  const loadFormPayment = useCallback(
    id => {
      firebase
        .firestore()
        .collection('formPayment')
        .orderBy('formPayment')
        .where('idProvider', '==', id)
        .get()
        .then(result => {
          dispatch(cleanPayment({} as PaymentModel));
          result.forEach(item => {
            dispatch(
              createPayment({
                id: item.data().id,
                formPayment: item.data().formPayment,
                change: item.data().change,
              }),
            );
          });
        });
    },
    [dispatch],
  );

  const loadNeighborhood = useCallback(
    id => {
      dispatch(cleanNeigh({} as NeighModel));
      firebase
        .firestore()
        .collection('neighborhood')
        .orderBy('name')
        .where('idProvider', '==', id)
        .where('active', '==', 'S')
        .get()
        .then(result => {
          result.forEach(item => {
            dispatch(
              createNeigh({
                id: item.data().id,
                code: item.data().code,
                idProvider: item.data().idProvider,
                name: item.data().name,
                feeDelivery: item.data().feeDelivery.replace(',', '.'),
                city: item.data().city,
                uf: item.data().uf,
                ibge: item.data().ibge,
              }),
            );
          });
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao carregar dados',
            description:
              'Ocorreu um erro ao tentar carregar dados. Tente novamente.',
          });
        });
    },
    [addToast, dispatch],
  );

  const loadProvider = useCallback(() => {
    // console.log('provider');
    const storeProvider = JSON.parse(String(localStorage.getItem('provider')));
    loadProducts(storeProvider.id);
    loadFormPayment(storeProvider.id);
    loadNeighborhood(storeProvider.id);
    if (storeProvider) {
      firebase
        .firestore()
        .collection('providers')
        .orderBy('name')
        .where('id', '==', storeProvider.id)
        .get()
        .then(result => {
          result.forEach(item => {
            const newItemProvider: ProviderModel = {
              id: storeProvider.id,
              logo: item.data().logo,
              email: item.data().email,
              password: item.data().password,
              cnpj: item.data().cnpj,
              fantasy: item.data().fantasy,
              feeDelivery: item.data().feeDelivery,
              feeDeliveryMode: item.data().feeDeliveryMode,
              ray: item.data().ray,
              open: item.data().open,
              singleEdge: item.data().singleEdge,
            };
            dispatch(createProvider(newItemProvider));
            localStorage.setItem('provider', JSON.stringify(newItemProvider));
          });
          setProviderLoaded(true);
        });
    }
  }, [dispatch, loadFormPayment, loadNeighborhood, loadProducts]);

  // const loadCustomer = useCallback(() => {
  //   const mwCustomer: CustomerModel = JSON.parse(
  //     String(localStorage.getItem('MW-CUSTOMER')),
  //   ) as CustomerModel;

  //   if (mwCustomer) {
  //     dispatch(createCustomer(mwCustomer));
  //   }
  // }, [dispatch]);

  const loadRequest = useCallback(() => {
    // console.log('request');
    const storeRequest = JSON.parse(String(localStorage.getItem('request')));
    if (storeRequest) {
      dispatch(
        createRequest({
          id: storeRequest.id,
          idCustomer: storeRequest.idCustomer,
          idProvider: storeRequest.idProvider,
          dateRequest: parseFloat(storeRequest.dateRequest),
          totalProducts: parseFloat(storeRequest.totalProducts),
          feeDelivery: parseFloat(storeRequest.feeDelivery),
          totalRequest: parseFloat(storeRequest.totalRequest),
          status: storeRequest.status,
          addressDifferent: storeRequest.addressDifferent,
          comeGet: storeRequest.comeGet,
          address: storeRequest.address,
          number: storeRequest.number,
          neigh: storeRequest.neigh,
          complement: storeRequest.complement,
          formPayment: storeRequest.formPayment,
          cellPhone: storeRequest.cellPhone,
          change: storeRequest.change,
          comments: storeRequest.comments,
        }),
      );
      setRequestLoaded(true);
    } else {
      setRequestLoaded(true);
    }
  }, [dispatch]);

  const loadFlavors = useCallback(() => {
    // console.log('flavor');

    const storeFlavors: FlavorModel[] = JSON.parse(
      String(localStorage.getItem('flavors')),
    );
    if (!storeFlavors) {
      setFlavorsLoaded(true);
      return;
    }
    if (storeFlavors.length > 0) {
      storeFlavors.forEach(flavors => {
        dispatch(
          createFlavor({
            id: flavors.id,
            idRequest: flavors.idRequest,
            code: flavors.code,
            description: flavors.description,
            unity: flavors.unity,
            amount: flavors.amount,
            price: flavors.price,
            totalFlavor: flavors.totalFlavor,
            codeEdge: flavors.codeEdge,
            edge: flavors.edge,
            priceEdge: flavors.priceEdge,
            totalEdge: flavors.totalEdge,
            total: flavors.total,
            comments: flavors.comments,
          }),
        );
      });
      setFlavorsLoaded(true);
    } else {
      setFlavorSelLoaded(true);
    }
  }, [dispatch]);

  const loadItems = useCallback(() => {
    // console.log('item');
    const storeItems: ItemModel[] = JSON.parse(
      String(localStorage.getItem('items')),
    );
    if (storeItems) {
      const convertedItems: ItemModel[] = [];
      storeItems.forEach(element => {
        convertedItems.push(element);
        // console.log(element);
      });
      // console.log(convertedItems);
      if (convertedItems.length > 0) {
        const listFlavors: FlavorModel[] = [];
        convertedItems.forEach(item => {
          let totalLoaded = item.total;
          if (item.combined === 'S') {
            // console.log(item);
            totalLoaded = 0;
            item.flavors.forEach(itemFlavor => {
              totalLoaded += itemFlavor.total;
              listFlavors.push({
                id: itemFlavor.id,
                idRequest: itemFlavor.idRequest,
                code: itemFlavor.code,
                description: itemFlavor.description,
                unity: itemFlavor.unity,
                amount: itemFlavor.amount,
                price: itemFlavor.price,
                totalFlavor: itemFlavor.totalFlavor,
                codeEdge: itemFlavor.codeEdge,
                edge: itemFlavor.edge,
                priceEdge: itemFlavor.priceEdge,
                totalEdge: itemFlavor.totalEdge,
                total: itemFlavor.total,
                comments: itemFlavor.comments,
              });
            });
          }

          const rItem: ItemModel = {
            id: item.id,
            idRequest: item.idRequest,
            code: item.code,
            description: item.description,
            unity: item.unity,
            amount: item.amount,
            price: item.price,
            total: totalLoaded,
            comments: item.comments,
            combined: item.combined,
            flavors: listFlavors,
          };
          // console.log(rItem);
          dispatch(createItem(rItem));
        });
        setItemsLoaded(true);
      } else {
        setItemsLoaded(true);
      }
    } else {
      setItemsLoaded(true);
    }
  }, [dispatch]);

  const loadGroupSelected = useCallback(() => {
    // console.log('groupselected');
    const storeGroupSelected = JSON.parse(
      String(localStorage.getItem('gselected')),
    );
    if (storeGroupSelected) {
      dispatch(
        createGroupSelected({
          id: storeGroupSelected.id,
          group: storeGroupSelected.group,
          fractioned: storeGroupSelected.fractioned,
          fractions: storeGroupSelected.fractions,
          web_borda_subgrupo: storeGroupSelected.web_borda_subgrupo,
        }),
      );
      setGroupSelLoaded(true);
    } else {
      setGroupSelLoaded(true);
    }
  }, [dispatch]);

  const loadFlavorSelected = useCallback(() => {
    // console.log('flavorselected');
    const flavorSelected = JSON.parse(
      String(localStorage.getItem('flavorSelected')),
    );
    if (flavorSelected) {
      dispatch(
        createFlavorSelected({
          id: flavorSelected.id,
          idRequest: flavorSelected.idRequest,
          code: flavorSelected.code,
          description: flavorSelected.description,
          unity: flavorSelected.unity,
          price: flavorSelected.price,
          codeEdge: flavorSelected.codeEdge,
          edge: flavorSelected.edge,
          priceEdge: parseFloat(flavorSelected.priceEdge),
          comments: flavorSelected.comments,
          amount: flavorSelected.amount,
          total: flavorSelected.total,
          totalEdge: flavorSelected.totalEdge,
          totalFlavor: flavorSelected.totalFlavor,
        }),
      );
      // console.log('flavorSelected storage' + flavorSelected.edge);
      setFlavorSelLoaded(true);
    } else {
      setFlavorSelLoaded(true);
    }
  }, [dispatch]);

  const loadItemSelected = useCallback(() => {
    // console.log('itemselected');
    const storeItemSelected = JSON.parse(
      String(localStorage.getItem('iselected')),
    );
    if (storeItemSelected) {
      dispatch(
        createItemSelected({
          id: storeItemSelected.id,
          idRequest: storeItemSelected.idRequest,
          code: storeItemSelected.code,
          description: storeItemSelected.description,
          unity: storeItemSelected.unity,
          amount: storeItemSelected.amount,
          price: storeItemSelected.price,
          total: storeItemSelected.total,
          comments: storeItemSelected.comments,
          combined: storeItemSelected.combined,
          flavors: storeItemSelected.flavors,
        }),
      );
      setItemSelLoaded(true);
    } else {
      setItemSelLoaded(true);
    }
  }, [dispatch]);

  const handleNavigation = useCallback(() => {
    if (callStorage.call === 'LOGIN') {
      history.push('/');
    } else if (callStorage.call === 'MYREQUESTS') {
      history.push('/myrequests');
    } else if (callStorage.call === 'REQUEST') {
      history.push('/request');
    } else if (callStorage.call === 'GROUPS') {
      history.push('/groups');
    } else if (callStorage.call === 'PRODUCTS') {
      history.push('/products');
    } else if (callStorage.call === 'COMBINEDPRODUCTS') {
      history.push('/combinedProducts');
    } else if (callStorage.call === 'COMBINEDORDER') {
      history.push('/combinedOrder');
    } else if (callStorage.call === 'COMBINEDEDGE') {
      history.push('/combinedEdge');
    } else if (callStorage.call === 'COMBINEDADD') {
      history.push('/combinedAdd');
    } else if (callStorage.call === 'COMBINEDCHECKOUT') {
      history.push('/combinedCheckout');
    } else if (callStorage.call === 'PRODUCTDETAIL') {
      history.push('/productDetail');
    } else if (callStorage.call === 'CHECKOUT') {
      history.push('/checkout');
    } else if (callStorage.call === 'MENUGROUPS') {
      history.push('/menuGroups');
    } else if (callStorage.call === 'MENUPRODUCTS') {
      history.push('/menuProducts');
    } else if (callStorage.call === 'PRECHECKOUT') {
      history.push('/precheckout');
    } else if (callStorage.call === 'NEIGHBORHOOD') {
      history.push('/neighborhood');
    } else {
      history.push('/');
    }
  }, [callStorage.call, history]);

  useEffect(() => {
    // navigator.geolocation.getCurrentPosition(position => {
    //   const { latitude, longitude } = position.coords;
    //   dispatch(createCoords({ lat: latitude, lng: longitude, zoom: 15 }));
    // });

    loadProvider();
  }, [dispatch, loadProvider]);

  useEffect(() => {
    if (providerLoaded) {
      // loadCustomer();
      loadRequest();
    }
  }, [loadRequest, providerLoaded]);

  useEffect(() => {
    if (requestLoaded) {
      loadItems();
    }
  }, [loadItems, requestLoaded]);

  useEffect(() => {
    if (itemsLoaded) {
      loadGroupSelected();
    }
  }, [itemsLoaded, loadGroupSelected]);

  useEffect(() => {
    if (groupSelLoaded) {
      loadFlavorSelected();
    }
  }, [groupSelLoaded, loadFlavorSelected]);

  useEffect(() => {
    if (flavorSelLoaded) {
      loadFlavors();
    }
  }, [flavorSelLoaded, loadFlavors]);

  useEffect(() => {
    if (flavorsLoaded) {
      loadItemSelected();
    }
  }, [flavorsLoaded, loadItemSelected]);

  useEffect(() => {
    if (itemSelLoaded) {
      handleNavigation();
    }
  }, [handleNavigation, itemSelLoaded]);

  return (
    <Container>
      <Logo className="imgLogin" src={logo} alt="Logo" />
      <Title>Mettre Smart</Title>
      <SubTitle>Carregando informações...</SubTitle>
    </Container>
  );
};

export default LoadStorage;
