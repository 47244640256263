import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FiCheck } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import Button from '../../components/Button';
import {
  Container,
  Logo,
  DetailText,
  SubDetailText,
  EmProducao,
  SaiuParaentrega,
} from './styles';
// import logo from '../../assets/order-food.png';
import { RootState } from '../../store/ducks/combineReducers';

const Success: React.FC = () => {
  const provider = useSelector((state: RootState) => state.provider.data);
  const history = useHistory();

  const handleLogin = useCallback(() => {
    history.push('/');
  }, [history]);

  return (
    <Container>
      <Logo src={provider.logo} alt="Logo" />
      <FiCheck color="green" size={100} />
      <DetailText>Pedido enviado com sucesso!</DetailText>
      {/* <DetailText>Acompanhe aqui seu pedido</DetailText> */}
      {/* <EmProducao>
        <DetailText>Pedido recebido</DetailText>
        <FiCheck color="green" size={20} />
      </EmProducao>
      <EmProducao>
        <DetailText>Em produção</DetailText>
        <FiCheck color="green" size={20} />
      </EmProducao>
      <SaiuParaentrega>
        <DetailText>Saiu para entrega</DetailText>
        <FiCheck color="green" size={20} />
      </SaiuParaentrega> */}
      <Button onClick={handleLogin}>OK</Button>
    </Container>
  );
};

export default Success;
