import styled from 'styled-components';

export const Container = styled.div`
  width: 90%;
  max-width: 500px;
  height: 90vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;

  h1 {
    margin-bottom: 20px;
  }

  Button {
    margin-top: 16px;
  }
`;

export const Logo = styled.img`
  width: 250px;
  margin-bottom: 20px;
`;

export const DetailText = styled.p`
  font-size: 16px;
  margin: 16px 0 28px;
  font-family: 'Roboto Regular';
`;

export const SubDetailText = styled.p`
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 4px;
  font-family: 'Roboto Regular';
  width: 100%;
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 40px;
  margin: 16px 16px;
  font-family: 'Roboto Regular';
`;

export const EmProducao = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  svg {
    margin-left: 16px;
  }
`;

export const SaiuParaentrega = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  svg {
    margin-left: 16px;
  }
`;
