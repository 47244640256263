import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Switch from 'react-switch';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { FiUser, FiMapPin } from 'react-icons/fi';
import getValidationErrors from '../../utils/getValidationErrors';
// import logo from '../../assets/order-food.png';
import { RootState } from '../../store/ducks/combineReducers';

import firebase from '../../services/firebaseConfig';

import {
  Container,
  Header,
  Title,
  Logo,
  SwitchArea,
  LabelSwitch,
  Boddy,
  Footer,
  AddressArea,
  Label,
  TotalArea,
  LabelTotal,
} from './styles';

import { useToast } from '../../hooks/toast';
import Buttom from '../../components/Button';
import Input from '../../components/Input';
import { RequestDTO } from './RequestDTO';
import { createCallLoadStorage } from '../../store/ducks/callLoadStorage/actions';
import { CustomerModel } from '../../models/CustomerModel';
import { NeighModel } from '../../models/NeighModel';
import { updateCustomer } from '../../store/ducks/customer/actions';

interface FormData {
  customerName: string;
  // neighborhood: string;
  address: string;
  number: string;
  complement: string;
}

const Checkout: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const history = useHistory();

  const dispatch = useDispatch();

  const provider = useSelector((state: RootState) => state.provider.data);
  const customer = useSelector((state: RootState) => state.customer.data);
  const request = useSelector((state: RootState) => state.request.data);
  const items = useSelector((state: RootState) => state.item.data);

  const neigh = useSelector((state: RootState) => state.neigh.data);

  const [comeGet, setComeGet] = useState<boolean>(false);

  const [neighSelected, setNeighSelected] = useState<NeighModel>(
    {} as NeighModel,
  );

  useEffect(() => {
    if (customer.neigh) {
      const indexNeigh = neigh.findIndex(opt => opt.name === customer.neigh);
      if (indexNeigh >= 0) {
        const selNeigh = neigh[indexNeigh];
        setNeighSelected(selNeigh as NeighModel);
      } else {
        setNeighSelected({} as NeighModel);
      }
    }
  }, [customer.neigh, neigh]);

  useEffect(() => {
    const loadStorage = () => {
      if (!provider.id) {
        dispatch(
          createCallLoadStorage({
            call: 'CHECKOUT',
          }),
        );
        history.push('/loadstorage');
      }
    };
    loadStorage();
  }, [dispatch, history, provider.id]);

  const feeDeliveryDepComeGet = useMemo(() => {
    if (comeGet) {
      return 0;
    }
    const tx = neighSelected.feeDelivery ? neighSelected.feeDelivery : 0;
    return tx;
  }, [comeGet, neighSelected]);

  const totalRequestCalc = useMemo(() => {
    // console.log(request.totalProducts);
    return Number(request.totalProducts) + Number(feeDeliveryDepComeGet);
  }, [feeDeliveryDepComeGet, request.totalProducts]);

  const handleSubmit = useCallback(
    async (data: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          address: Yup.string().required('Precisamos do nome da sua rua'),
          customerName: Yup.string().required('Informe seu nome'),
          // neighborhood: Yup.string().required('Precisamos saber seu bairro'),
          number: Yup.string().required('Inform o número da sua residência'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const newCustomer: CustomerModel = {
          id: customer.id,
          idProvider: provider.id,
          name: data.customerName,
          whats: customer.whats,
          cpf: customer.cpf ? customer.cpf : '',
          zipcode: customer.zipcode ? customer.zipcode : '',
          address: data.address,
          number: data.number,
          neigh: request.neigh,
          feeDelivery: neighSelected.feeDelivery,
          complement: data.complement,
          city: neighSelected.city,
          uf: neighSelected.uf,
          comments: customer.comments ? customer.comments : '',
          latitude: 0, // customer.latitude,
          longitude: 0, // customer.longitude,
          read: customer.read ? customer.read : false,
        };
        dispatch(updateCustomer(newCustomer));

        firebase
          .firestore()
          .collection('customers')
          .doc(customer.id)
          .set(newCustomer)
          .then(() => {
            const sentData: RequestDTO = {
              id: request.id,
              idCustomer: customer.id,
              idProvider: provider.id,
              dateRequest: request.dateRequest,
              totalProducts: request.totalProducts,
              feeDelivery: feeDeliveryDepComeGet,
              totalRequest: totalRequestCalc,
              status: request.status,
              address: data.address,
              number: data.address,
              idNeigh: String(
                neigh.find(opt => opt.name === request.neigh)?.id,
              ),
              neigh: request.neigh,
              complement: data.complement,
              formPayment: request.formPayment,
              items,
              read: false,
              dataCustomer: newCustomer,
              comeGet,
              comments: request.comments ? request.comments : '',
              latitude: 0, // coords.lat,
              longitude: 0, // coords.lng,
              change: Number(request.change) / 100,
            };
            // console.log(sentData);
            firebase
              .firestore()
              .collection('requests')
              .doc(request.id)
              .set(sentData)
              .then(() => {
                localStorage.setItem(
                  'MW-CUSTOMER',
                  JSON.stringify(newCustomer),
                );
                addToast({
                  type: 'success',
                  title: 'Pedido enviado com sucesso!',
                  description: 'Seu pedido foi enviado com sucesso!',
                });
                history.push('/success');
              });
          });
      } catch (err) {
        // console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          // return;
        }
        addToast({
          type: 'error',
          title: 'Erro ao enviar o pedido!',
          description:
            'Ocorreu um erro ao enviar o pedido! Observe se todos os campos solicitador estão preenchidos.',
        });
      }
    },
    [
      addToast,
      comeGet,
      customer.comments,
      customer.cpf,
      customer.id,
      customer.read,
      customer.whats,
      customer.zipcode,
      dispatch,
      feeDeliveryDepComeGet,
      history,
      items,
      neigh,
      neighSelected.city,
      neighSelected.feeDelivery,
      neighSelected.uf,
      provider.id,
      request.change,
      request.comments,
      request.dateRequest,
      request.formPayment,
      request.id,
      request.neigh,
      request.status,
      request.totalProducts,
      totalRequestCalc,
    ],
  );

  const handleComeGet = useCallback((checked: boolean) => {
    setComeGet(checked);
  }, []);

  const handleBack = useCallback(() => {
    history.push('/precheckout');
  }, [history]);

  return (
    <>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          customerName: customer.name,
          address: customer.address,
          number: customer.number,
          complement: customer.complement,
        }}
      >
        <Container>
          <Header>
            <Logo src={provider.logo} alt="Logo" />
            <Title>Finalizando o pedido</Title>
          </Header>
          <Boddy>
            <AddressArea>
              <Label>Seu nome</Label>
              <Input
                fontSize="14px"
                align="justify"
                name="customerName"
                icon={FiUser}
              />
              <Label>Endereço (rua, av., logradouro)</Label>
              <Input
                fontSize="14px"
                align="justify"
                name="address"
                icon={FiMapPin}
              />
              <Label>Número</Label>
              <Input
                fontSize="14px"
                align="justify"
                name="number"
                icon={FiMapPin}
              />
              <Label>Complemento</Label>
              <Input
                fontSize="14px"
                align="justify"
                name="complement"
                icon={FiMapPin}
              />
            </AddressArea>
          </Boddy>
          <SwitchArea>
            <Switch onChange={handleComeGet} checked={comeGet} />
            <LabelSwitch>Retirar no balcão</LabelSwitch>
          </SwitchArea>
          <TotalArea>
            <LabelTotal>
              {`Valor total dos produtos ${Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(request.totalProducts)}`}
            </LabelTotal>
            <LabelTotal>
              {`Taxa de entrega ${Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(feeDeliveryDepComeGet)}`}
            </LabelTotal>
            <LabelTotal>
              {`Valor total do pedido ${Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(totalRequestCalc)}`}
            </LabelTotal>
          </TotalArea>
          <Footer>
            <Buttom onClick={handleBack}>Voltar</Buttom>
            <Buttom colorButton="green" type="submit">
              Enviar pedido
            </Buttom>
          </Footer>
        </Container>
      </Form>
    </>
  );
};

export default Checkout;
