import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/ducks/combineReducers';
import Button from '../../components/Button';
import { GroupModel } from '../../models/GroupModel';
// import logo from '../../assets/order-food.png';

import { Logo, Container, SubTitle, Boddy, Footer } from './styles';

import { createGroupSelected } from '../../store/ducks/groupSelected/actions';

import GroupItem from './GroupItem';
import { createCallLoadStorage } from '../../store/ducks/callLoadStorage/actions';

const MenuGroups: React.FC = () => {
  const dispatch = useDispatch();

  const groups = useSelector((state: RootState) => state.groups.data);
  const provider = useSelector((state: RootState) => state.provider.data);
  // const request = useSelector((state: RootState) => state.request.data);

  const history = useHistory();

  useEffect(() => {
    function loadStorage() {
      // console.log(groups);
      if (!provider.id) {
        dispatch(createCallLoadStorage({ call: 'GROUPS' }));
        history.push('/loadstorage');
      }
    }
    loadStorage();
  }, [dispatch, history, provider.id]);

  const handleGroupSelect = useCallback(
    (data: GroupModel) => {
      dispatch(
        createGroupSelected({
          id: data.id,
          group: data.group,
          fractioned: data.fractioned,
          fractions: data.fractions,
          web_borda_subgrupo: data.web_borda_subgrupo,
        }),
      );
      localStorage.setItem(
        'gselected',
        JSON.stringify({
          id: data.id,
          group: data.group,
          fractioned: data.fractioned,
          fractions: data.fractions,
        }),
      );

      if (data.fractioned === 'S') {
        history.push('/combinedProducts');
      } else {
        history.push('/products');
      }
    },
    [dispatch, history],
  );

  const handleBack = useCallback(() => {
    history.push('/');
  }, [history]);

  const handleRequest = useCallback(() => {
    history.push('/request');
  }, [history]);

  return (
    <Container>
      <Logo className="logoGroup" src={provider.logo} alt="Logo" />
      <SubTitle>Selecione um grupo</SubTitle>
      <Boddy>
        {groups.map(item => (
          <GroupItem
            data={item}
            key={item.id}
            handleGroupSelect={() => handleGroupSelect(item)}
          />
        ))}
      </Boddy>
      <Footer>
        <Button
          colorButton="green"
          onClick={() => handleRequest()}
          className="groupButton"
        >
          Meu pedido
        </Button>
        <Button onClick={() => handleBack()} className="groupButton">
          Cancelar
        </Button>
      </Footer>
    </Container>
  );
};

export default MenuGroups;
