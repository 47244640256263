import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const DetailText = styled.p`
  font-size: 10px;
`;
